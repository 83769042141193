import React from "react";
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import {
  Link,
} from "gatsby-theme-material-ui";
import CssBaseline from "@mui/material/CssBaseline";
import {Grid} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    TextField: {
      styleOverrides: {
        root: {
          'margin-top': '1rem'
        },
      },
    }
  }
});

const StaffPage = ({data}) => {
  return (
    <ThemeProvider theme={theme}>
    <Layout pageTitle="Staff">
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <h2>2018 Staff</h2>
          <ul>
            {
              data.allMdx.nodes.map((node) => (
                <article key={node.id}>
                    <Link to={`/staff/${node.slug}`}>
                      {node.frontmatter.name}
                    </Link> -- {node.frontmatter.subhead}
                </article>
              ))
            }
          </ul>
        </Grid>
      </Grid>
    </Layout>
    </ThemeProvider>
  );
};

export const query = graphql`
  query {
    allMdx(
    filter: {fields: {source: {eq: "staff"}}},
    sort: {fields: [frontmatter___sortPriority,frontmatter___name],order: ASC}

  ) {
    nodes {
      frontmatter {
        sortPriority
        name
        subhead
      }
      id
      slug
      fields {
        source
      }
    }
  }

  }
`

export default StaffPage;
